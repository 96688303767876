.errorPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorPage img{
    width: 20%;
    height: 20%;
}


@media (max-width: 1050px) {
    .errorPage img{
        width: 50%;
        height: 50%;
    }
}