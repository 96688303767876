#mobileMenu {
    position: absolute;
    width: 250px;
    height: 100%;
    background-color: darkgrey;
    z-index: 1001;
}

#mobileMenu ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 100px;
}

#mobileMenu ul li {
    height: 70px;
}

@keyframes openMobileMenu {
    0% {
        transform: translate(-100%);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes closeMobileMenu {
    0% {
        transform: translate(0);
    }
    100% {
        transform: translate(-100%);
    }
}

.openMobileMenu {
    animation: openMobileMenu 500ms;
}

.closeMobileMenu {
    animation: closeMobileMenu 500ms;
}