#burgerMenu {
    flex-direction: column;
    justify-content: space-between;
    width: 50px;
    height: 50px;
    display: none;
    z-index: 1001;
}

#burgerMenu span {
    background-color: black;
    width: 100%;
    height: 7px;
    transform-origin: left;
}

@media (max-width: 1050px) {
    #burgerMenu {
        display: flex
    }
}

@keyframes openSpan1 {
    0% {
        transform: rotate(0deg);
        width: 50px;
    }
    100% {
        transform: rotate(45deg);
        width: 61px;
    }
}

@keyframes closeSpan1 {
    0% {
        transform: rotate(45deg);
        width: 61px;
    }
    100% {
        transform: rotate(0deg);
        width: 50px;
    }
}

@keyframes openSpan3 {
    0% {
        transform: rotate(0deg);
        width: 50px;
    }
    100% {
        transform: rotate(-45deg);
        width: 61px;
    }
}

@keyframes closeSpan3 {
    0% {
        transform: rotate(-45deg);
        width: 61px;
    }
    100% {
        transform: rotate(0deg);
        width: 50px;
    }
}

@keyframes showSpan2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
}



.openSpan1 {
    animation: openSpan1 500ms forwards;
}

.openSpan3 {
    animation: openSpan3 500ms forwards;
}

.closeSpan1 {
    animation: closeSpan1 500ms;
}

.closeSpan3 {
    animation: closeSpan3 500ms;
}

.showSpan2 {
    animation: showSpan2 500ms;
}