#speedometerWrapper{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

#wrapperRoad p{
    text-align: center;
    font-size: 50px;
    margin-bottom: 10px;
}

#wrapperSpeed p{
    font-size: 100px;
}