.circle{
    text-align: center;
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 30px red solid;
    margin-top: 25px;
}

.circle-unlimited{
    rotate: 45deg;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 5px black solid;
    margin-top: 25px;
}

.circle-line{
    margin: 3px;
    width: 5px;
    height: 250px;
    background-color: black;
}

.circle p{
    width: 100%;
    margin-right: 6px;
    font-weight: bold;
    font-family: "Arial", serif;
    letter-spacing: -6px;
}

.speedLimitHigh {
    font-size: 120px;
}

.speedLimitLow {
    font-size: 140px;
}