.header {
    width: available;
    height: 100px;
    background-color: lightgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

img {
    width: 50px;
    height: 50px;
}

.profile {
    width: 50px;
    height: 50px;
    display: none;
}

nav {
    height: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: darkgrey;
}

nav ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
}

li {
    cursor: pointer;
    display: flex;
    height: 100%;
    width: 100%;
    font-weight: bold;
}

li:hover {
    box-shadow: -2px -2px 10px #424242, 2px 2px 10px #424242;
    transition: box-shadow 0.3s;
}

li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: black;
    text-decoration: none;
}

@media (max-width: 1050px) {
    .header {
        justify-content: space-between;
    }

    .profile {
        display: flex;
    }

    nav {
        display: none;
    }
}
