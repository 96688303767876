html {
    height: 100%;
}

body, #root {
    height: 100%;
}

.content {
    margin-left: 2%;
    margin-right: 2%;
    height: calc(100% - 100px);
}

p, h1 {
    font-family: "Times New Roman", serif;
}

p {
    margin: 0;
}

h1 {
    display: flex;
    justify-content: center;
}

.map {
    height: 400px;
    width: 100%;
}

.streakerWrapper {
    display: flex;
    justify-content: space-between;
    border-style: outset;
    margin-bottom: 5px;
    padding: 7px;
    height: fit-content;
}

.duration {
    color: darkgrey;
}